const slugify = require("slugify")

function getSongPath({ id, title }) {
  const slug = slugify(title, {
    replacement: "-",
    remove: /[*+~.()'"!:@?΄,]/g,
    lower: true,
  })

  return `/songs/${slug}-${id}`
}

function getArtistPath({ id, name }) {
  const slug = slugify(name, {
    replacement: "-",
    remove: /[*+~.()'"!:@?΄,]/g,
    lower: true,
  })

  return `/artists/${slug}-${id}`
}

function getTranslationPath({ id, title }) {
  const slug = slugify(title, {
    replacement: "-",
    remove: /[*+~.()'"!:@?΄,]/g,
    lower: true,
  })

  return `/translations/${slug}-${id}`
}

exports.getSongPath = getSongPath
exports.getArtistPath = getArtistPath
exports.getTranslationPath = getTranslationPath
