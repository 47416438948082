import React from "react"
import { graphql, Link } from "gatsby"
import ISO6391 from "iso-639-1"
import marked from "marked"
import { FaCertificate, FaStar, FaMusic } from "react-icons/fa"
import Layout from "../components/layout"
import { getTranslationPath, getArtistPath } from "../utils/url"
import getTranslationRefTitle from "../utils/getTranslationRefTitle"
import removeDiacritics from "../utils/removeDiacritics"
import SEO from "../components/seo"
import youtubeUrl from "youtube-url"
import YouTube from "react-youtube"
import getYouTubeID from "get-youtube-id"

export default ({ data }) => {
  const song = data.strapiSong
  const translations = data.allStrapiTranslation.nodes

  return (
    <Layout>
      <SEO title={song.title} />
      <article>
        <ol className="breadcrumb">
          <li>
            <Link to="/songs">ΑΡΧΕΙΟ ΥΜΝΩΝ</Link>
          </li>
          <li>{removeDiacritics(song.title).toUpperCase()}</li>
        </ol>
        <h1 className="my-4 font-semibold font-sans text-gray-900 text-3xl md:text-4xl leading-tight">
          {song.title}
        </h1>
        <section className="my-2 text-sm">
          {song.originalTitle && (
            <div>
              <span className="mr-1 text-gray-600">Πρωτότυπος τίτλος:</span>
              <span>{song.originalTitle}</span>
            </div>
          )}
          {song.language && (
            <div>
              <span className="mr-1 text-gray-600">Γλώσσα:</span>
              <span>
                {ISO6391.getName(song.language.toLowerCase()) || song.language}
              </span>
            </div>
          )}
          {song.songwriters.length !== 0 && (
            <div>
              <span className="mr-1 text-gray-600">
                Μουσική{song.lyricists.length === 0 && " - Στίχοι"}:
              </span>
              <ul className="comma-separated inline-block">
                {song.songwriters.map(songwriter => (
                  <li key={songwriter.id} className="inline-block">
                    <Link
                      to={getArtistPath(songwriter)}
                      className="text-blue-700 hover:underline"
                    >
                      {songwriter.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {song.lyricists.length !== 0 && (
            <div>
              <span className="mr-1 text-gray-600">Στίχοι:</span>
              <ul className="comma-separated inline-block">
                {song.lyricists.map(lyricist => (
                  <li key={lyricist.id} className="inline-block">
                    <Link
                      to={getArtistPath(lyricist)}
                      className="text-blue-700 hover:underline"
                    >
                      {lyricist.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {song.pubyear && (
            <div>
              <span className="mr-1 text-gray-600">Έτος έκδοσης:</span>
              <span>{song.pubyear}</span>
            </div>
          )}
          {song.copyright && (
            <div>
              <span className="mr-1 text-gray-600">Copyright:</span>
              <span>&copy; {song.copyright}</span>
            </div>
          )}
        </section>
        {song.lyrics && (
          <div
            className="my-6 rtf text-lg md:text-xl text-gray-900 leading-normal font-serif"
            dangerouslySetInnerHTML={{
              __html: marked(song.lyrics, {
                breaks: true,
                gfm: true,
              }),
            }}
          />
        )}
        {song.isMusicSheetAvailable && (
          <ul className="my-2 uppercase text-xs font-semibold text-gray-900 md:flex md:items-center -mx-1">
            <li className="flex items-center rounded mx-1 my-1">
              <FaMusic size={12} />
              <span className="ml-1">ΔΙΑΤΙΘΕΤΑΙ ΠΑΡΤΙΤΟΥΡΑ</span>
            </li>
          </ul>
        )}
        {song.externalLink && (
          <div className="my-2 text-sm">
            {youtubeUrl.valid(song.externalLink) ? (
              <YouTube videoId={getYouTubeID(song.externalLink)} />
            ) : (
              <a
                href={song.externalLink}
                target="_blank"
                rel="noreferrer noopener"
                className="text-blue-800 hover:underline font-sans text-sm"
              >
                {song.externalLink}
              </a>
            )}
          </div>
        )}
      </article>
      {translations.length !== 0 && (
        <aside className="mt-10">
          <h2 className="font-semibold font-sans text-gray-900 mb-2 text-xl md:text-2xl">
            {song.language === "EL" ? "Διασκευές" : "Μεταφράσεις"}
          </h2>
          <ul className="list-disc list-inside">
            {translations.map(translation => {
              return (
                <li key={translation.id} className="my-2">
                  <Link
                    to={getTranslationPath(translation)}
                    className="text-blue-700 hover:underline inline-flex items-center"
                  >
                    {getTranslationRefTitle(translation)}
                    {translation.isOfficialTranslation && (
                      <FaCertificate
                        size={12}
                        className="ml-1"
                        title="Επίσημη Μετάφραση"
                      />
                    )}
                    {translation.isApprovedByCA && (
                      <FaStar
                        size={12}
                        className="ml-1"
                        title="Προτείνεται από ΣΕΧΚ"
                      />
                    )}
                    {translation.isMusicSheetAvailable && (
                      <FaMusic
                        size={12}
                        className="ml-1"
                        title="Διατίθεται παρτιτούρα στα ελληνικά"
                      />
                    )}
                  </Link>
                </li>
              )
            })}
          </ul>
        </aside>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiSong(strapiId: { eq: $id }) {
      title: Title
      originalTitle
      pubyear
      lyrics: Lyrics
      language: Language
      copyright: Copyright
      externalLink: ExternalLink
      lyricists {
        id
        name
      }
      songwriters {
        id
        name
      }
      translations {
        title
        pubyear
        id
        isApprovedByCA
        isOfficialTranslation
      }
      isMusicSheetAvailable: IsMusicSheetAvailable
    }
    allStrapiTranslation(
      filter: { song: { id: { eq: $id } } }
      sort: { fields: pubyear, order: ASC }
    ) {
      nodes {
        id: strapiId
        pubyear
        title
        isApprovedByCA
        isOfficialTranslation
        isMusicSheetAvailable
        translators {
          id
          name
        }
      }
    }
  }
`
